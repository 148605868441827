
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconClose from '@/components/icons/IconClose.vue';

@Component({
  components: { BaseButton, IconClose },
})
export default class BaseModalForPages extends Vue {
  @Prop({ default: {} }) textsForModal!: Record<string, string>;
  @Prop({ default: true }) isNextButton!: boolean;
  @Prop({ default: 'small' }) size!: string;
  @Prop({ default: false }) isCloseBtn!: boolean;

  mainButtonModalClicked(): void {
    this.$emit('mainButtonModalClicked');
  }
  closeModal(): void {
    this.$emit('closeModal');
  }
}
